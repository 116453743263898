<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1 gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper>
        <template v-slot:left>
          <router-link :to="{ name: 'r_devices', params: $route.params }">
            <button style="--color-1: #fff;">
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("manage-users", "users", "users") }}
        </div>
        <template v-slot:right>
          <button @click="search = !search">
            <icon icon="#cx-hea1-search" class="icon-hea1" />
          </button>
          <button @click="initSubmit()" style="--color-1: #fff;">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        :hasFilters="false"
        :selectedFilters="selectedFilters"
        @removeSelectedFilter="removeSelectedFilter"
      ></search>
    </template>
    <ul class="clebex-item-section pill">
      <li class="clebex-item-section-item">
        <button class="clebex-item-content-wrapper" @click="selectUser(null)">
          <span class="label"
            ><span class="highlight">{{
              displayLabelName("devices", "users", "none")
            }}</span></span
          >
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                v-if="selectedUser == null"
                icon="#cx-app1-checkmark"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <ul class="clebex-item-section pill" v-if="users && users.length">
      <li class="clebex-item-section-item" v-for="user in users" :key="user.id">
        <button
          class="clebex-item-content-wrapper"
          @click="selectUser(user.id)"
        >
          <span class="label"
            ><span class="highlight">{{ user.name }}</span></span
          >
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                v-if="user.id == selectedUser"
                icon="#cx-app1-checkmark"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { preferenceCategoryIds } from "@/services/preference-options";

export default {
  name: "DeviceUsers",
  data() {
    return {
      show: false,
      selectedUser: null,
      editing: false,
      userFormTemplate: preferenceCategoryIds.userFormTemplate,
      search: null,
      childData: ""
    };
  },
  computed: {
    ...mapState("devices", ["deviceUsers", "selectedDevice"]),
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true
        });
      }
    },
    users() {
      const query = this.searchQuery;
      if (this.deviceUsers && this.deviceUsers.length) {
        if (query && query.length > 1) {
          return this.deviceUsers.filter(item =>
            `${item.first_name} ${item.last_name}`
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        } else {
          return this.deviceUsers;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("devices", ["getUsers", "setDeviceUser", "selectDevice"]),
    selectUser(id) {
      this.selectedUser = id;
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
    },
    initSubmit() {
      if (this.selectedDevice) {
        this.setDeviceUser(this.selectedUser);

        this.$router.push({
          name: "r_devices",
          params: {}
        });
      }
    }
  },
  created() {
    if (!this.selectedDevice) {
      this.$router.push({
        name: "r_devices",
        params: {}
      });
    } else {
      this.getUsers();
      if (this.selectedDevice.assigned_user.length > 0) {
        this.selectedUser = this.selectedDevice.assigned_user[0].id;
      } else {
        this.selectedUser = null;
      }
    }
  },
  watch: {
    selectedDevice() {
      this.getUsers();
      if (this.selectedDevice.assigned_user.length > 0) {
        this.selectedUser = this.selectedDevice.assigned_user[0].id;
      } else {
        this.selectedUser = null;
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  }
};
</script>
